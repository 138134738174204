/* body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* .App-logo {
  grid-row: 1 / span 99;
  grid-column: 1 / span 99
} */


.main-grid {
  width: 100%;
  height: 500px;
  display: grid;
  grid-template: 0px 1fr / 1fr;

  
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* background-color: #555; */
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  /* cursor: pointer; */
  border-radius: 5px;
  text-align: center;
}

.homecity{
  font-weight: bold;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 10px;
  padding: 40px;
  border-radius: 50%;
  background-image: radial-gradient(white,rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7),rgba(255, 255, 255, 0.3),rgba(255, 255, 255, 0),rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) );
}


.section{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

nav {
  background-color: darkred !important;
  position: fixed;
  z-index: 1; }

nav ul a:hover {
  background-color: rgba(255, 255, 255, 0.3); }

.brand-logo {
  max-width: 85%; }

.content-grid {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr;
  position: relative; }

.middle-grid-flexbox {
  grid-column: 2;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center; }

.icon {
  height: 50px;
  width: 50px; }

.circle {
  background-color: rgba(255, 255, 255, 0.6);
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0px 0px 30px #888;
  padding: 0.5em 0.6em; }

.circle:hover {
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
          animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px; }

@-webkit-keyframes shake {
  10%, 50% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0); }
  20%, 40% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0); }
  30% {
    -webkit-transform: translate3d(-3px, 0, 0);
            transform: translate3d(-3px, 0, 0); } }

@keyframes shake {
  10%, 50% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0); }
  20%, 40% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0); }
  30% {
    -webkit-transform: translate3d(-3px, 0, 0);
            transform: translate3d(-3px, 0, 0); } }

.title {
  font-size: 5vw;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000; }

footer {
  background-color: #000000; }

.centerdiv {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }

.row {
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around; }

.row:after {
  content: none !important; }

.row_white {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around; }

.card {
  width: 500px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }

.card-title {
  text-align: center; }

