footer {
    //background-color: #3f51b5;
    background-color: #000000 ;


}

.centerdiv{
    display: flex;
    justify-content: center;
}