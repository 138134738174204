// .brand-logo {
//     text-align: center 

// }

nav {
  //background-color: #3f51b5;
  //background-color: rgba(63, 81, 181, 0.5);

  //background-color: rgba(255, 183, 77, 1);
  //background-color: rgba(0, 0, 0, 1);
  background-color: darkred !important;
  position: fixed;
  z-index: 1;

  //background-color: rgba(0, 0, 0, 0.7);

  //position: relative;
}

// .sticky{
//   position: fixed;
//   top: 0;
//   width: 100%;
// }

// .sticky + .content {
//   padding-top: 60px;
// }

nav ul a:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

// nav::before
// {

//    display: block;
//    position: absolute;
//    z-index: -1;
//    width: 100%;
//    height: 100%;
//    background:red;
//    opacity: .5;

// }

// nav::after {
//     content: "";
//     //background: url(image.jpg);
//     opacity: 0.5;
//     background-color: #3f51b5;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     position: absolute;
//     z-index: -1;
//   }

.brand-logo {
  max-width: 85%;
}
