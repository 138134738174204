.content-grid {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr;
  position: relative;
}

.middle-grid-flexbox {
  grid-column: 2;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.icon {
    //animation: App-logo-spin infinite 20s linear;
  height: 50px;
  width: 50px;
  //pointer-events: none;
}


.circle{
  background-color: rgba(255, 255, 255, 0.6);
  // border-radius: 50%
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0px 0px 30px #888;
  padding: 0.5em 0.6em;
}

.circle:hover {
  background-color: rgba(255, 255, 255, 0.3);
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;

}

@keyframes shake {
  10%, 50% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 40%  {
    transform: translate3d(2px, 0, 0);
  }

  30% {
    transform: translate3d(-3px, 0, 0);
  }
}

.title{
  font-size: 5vw;

  text-shadow:
		-2px -2px 0 #000,
		2px -2px 0 #000,
		-2px 2px 0 #000,
		2px 2px 0 #000;
}


// .button-hover:hover {
//   background-color: rgba(255, 204, 128, 0.3);
//   //animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
//   //transform: translate3d(0, 0, 0);
//   backface-visibility: hidden;
//   perspective: 1000px;

// }


